import { AccountCircle, FileCopy, Logout } from "@mui/icons-material";
import {
	Avatar,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import avatar6 from "./../../assets/images/avatars/8.jpg";

const AppHeaderDropdown = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logout = () => {
		localStorage.clear();
		navigate("/");
		window.location.reload();
	};

	const csvHeaders = () => {
		navigate("/Home/CsvHeaders");
	};

	const userDetails = localStorage.getItem("userDetails");
	const userName = userDetails ? JSON.parse(userDetails).name : "";
	const userWaBaName = userDetails ? JSON.parse(userDetails).waBaName : "";

	return (
		<>
			<IconButton onClick={handleClick} color="inherit">
				<Avatar src={avatar6} />
				<Typography variant="body1" sx={{ ml: 1 }}>
					{userName}
				</Typography>
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				PaperProps={{
					sx: {
						width: 220,
						maxWidth: "100%",
					},
				}}
			>
				<MenuItem disabled>
					<Avatar src={avatar6} sx={{ mr: 1 }} />
					<div>
						<Typography variant="subtitle2">{userName}</Typography>
						<Typography variant="body2" color="textSecondary">
							{userWaBaName}
						</Typography>
					</div>
				</MenuItem>
				<Divider />
				<MenuItem onClick={() => navigate("/Home/Profile")}>
					<AccountCircle sx={{ mr: 1 }} />
					Profile
				</MenuItem>
				<MenuItem onClick={csvHeaders}>
					<FileCopy sx={{ mr: 1 }} />
					Manage Headers
				</MenuItem>
				<Divider />
				<MenuItem onClick={logout}>
					<Logout sx={{ mr: 1 }} />
					Logout
				</MenuItem>
			</Menu>
		</>
	);
};

export default AppHeaderDropdown;
