import React from "react";

const EmbeddedSignUp = React.lazy(
	() => import("./views/EmbeddedSignUp/EmbeddedSignup"),
);

const Schedules = React.lazy(() => import("./views/Stepper/Stepper"));

const CustomerData = React.lazy(
	() => import("./views/CustomerData/CustomerData"),
);

const UploadUsers = React.lazy(() => import("./views/UploadUsers/UploadUsers"));

const Charts = React.lazy(() => import("./views/ChartComponent/Chart"));

const Scheduling = React.lazy(() => import("./views/Scheduling/Scheduling"));

const EditSchedule = React.lazy(
	() => import("./views/EditStepper/EditStepper"),
);

const CsvHeaders = React.lazy(() => import("./views/CSVFields/DynamicForm"));

const MessageTemplate = React.lazy(
	() => import("./views/MessageTemplates/MessageTemplate"),
);

const ViewMessageTemplate = React.lazy(
	() => import("./views/MessageTemplates/ViewMessageTemplate"),
);

const CreateMessageTemplate = React.lazy(
	() => import("./views/CustomTemplate/CreateTemplate"),
);
// const EditMessageTemplate = React.lazy(
// 	() => import("./views/CustomTemplate/EditTemplate"),
// );

const ScheduleFlow = React.lazy(
	() => import("./views/ScheduleFlows/ScheduleFlows"),
);

const CreateScheduleFlow = React.lazy(
	() => import("./views/ScheduleFlows/CreateScheduleFlow/CreateScheduleFlow"),
);

const AssignUsers = React.lazy(
	() => import("./views/ScheduleFlows/AssignUsers/AssignUsers"),
);

const AssignFlow = React.lazy(
	() => import("./views/ScheduleFlows/AssignUsers/AssignFlow"),
);
const AssignedFlows = React.lazy(
	() => import("./views/ScheduleFlows/AssignedFlows/AssignedFlows"),
);

const EditAssignFlow = React.lazy(
	() => import("./views/ScheduleFlows/EditFlows/EditScheduleFlow"),
);

const routes = [
	// { path: '/', exact: true, name: 'Home' },
	{ path: "/Dashboard", exact: true, name: "Dashboard", element: Charts },
	{ path: "/EmbeddedSignUp", name: "EmbeddedSignUp", element: EmbeddedSignUp },
	{ path: "/Schedules/NewSchedule", name: "New Schedules", element: Schedules },
	{ path: "/CustomerData", name: "CustomerData", element: CustomerData },
	{
		path: "/CustomerData/uploadUsers",
		name: "uploadUsers",
		element: UploadUsers,
	},
	{ path: "/Schedules", name: "Schedules", element: Scheduling },
	{
		path: "/Schedules/EditSchedule",
		name: "Edit Schedules",
		element: EditSchedule,
	},
	{ path: "/CsvHeaders", name: "CSV Headers", element: CsvHeaders },
	{
		path: "/MessageTemplate",
		name: "Message Templates",
		element: MessageTemplate,
	},
	{
		path: "/MessageTemplate/View",
		name: "View Message Templates",
		element: ViewMessageTemplate,
	},
	{
		path: "/MessageTemplate/Create",
		name: "View Message Templates",
		element: CreateMessageTemplate,
	},
	{
		path: "/MessageTemplate/Edit",
		name: "View Message Templates",
		element: CreateMessageTemplate,
	},
	{ path: "/ScheduleFlow", name: "Schedule Flows", element: ScheduleFlow },
	{
		path: "/ScheduleFlow/Create",
		name: "Create Schedule Flow",
		element: CreateScheduleFlow,
	},
	{
		path: "/ScheduleFlow/AssignUsers",
		name: "Assign Users",
		element: AssignUsers,
	},
	{
		path: "/ScheduleFlow/AssignFlow",
		name: "Assign Flow",
		element: AssignFlow,
	},
	{
		path: "/ScheduleFlow/AssignedFlows",
		name: "Assigned Flows",
		element: AssignedFlows,
	},
	{
		path: "/ScheduleFlow/AssignedFlows/Edit",
		name: "Edit Assigned Flows",
		element: EditAssignFlow,
	},
];

export default routes;
