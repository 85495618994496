import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";
import { X } from "lucide-react";
import { useLocation } from "react-router-dom";
const Nav = () => {
	const [showLinks, setShowLinks] = useState(false);
	const location = useLocation();
	const path = location.pathname;
	console.log(path);
	return (
		<nav className="fixed left-0 top-0 z-50 flex w-svw flex-col items-center justify-between bg-gray-600 p-4 text-white md:p-8">
			<div className="flex w-full justify-between">
				<div>
					<Link className="text-xl" to="/">
						C H A T Y F Y
					</Link>
				</div>
				<div className="hidden md:block">
					<div className="flex flex-col items-center justify-between gap-6 md:flex-row md:gap-24">
						<Link
							className={`rounded-md ${path === "/" ? "bg-[rgba(0,0,0,0.15)] hover:bg-[rgba(0,0,0,0.25)]" : ""} px-3 py-2 transition-colors`}
							to="/"
						>
							Home
						</Link>
						<Link
							className={`rounded-md ${path === "/signup" ? "bg-[rgba(0,0,0,0.15)] hover:bg-[rgba(0,0,0,0.25)]" : ""} px-3 py-2 transition-colors`}
							to="/signup"
						>
							Register Here
						</Link>
					</div>
				</div>
				<div className="md:hidden">
					<button onClick={() => setShowLinks(!showLinks)}>
						{showLinks ? <X /> : <Menu />}
					</button>
				</div>
			</div>
			<div
				className={`flex flex-col items-center justify-center gap-6 transition-all duration-300 md:hidden ${showLinks ? "opacity-100" : "opacity-0"}`}
				style={{
					maxHeight: showLinks ? "100px" : "0px",
					overflow: "hidden",
				}}
			>
				<Link
					className={`rounded-md ${path === "/" ? "bg-[rgba(0,0,0,0.15)] hover:bg-[rgba(0,0,0,0.25)]" : ""} px-3 py-2 transition-colors`}
					to="/"
				>
					Home
				</Link>
				<Link
					className={`rounded-md ${path === "/signup" ? "bg-[rgba(0,0,0,0.15)] hover:bg-[rgba(0,0,0,0.25)]" : ""} px-3 py-2 transition-colors`}
					to="/signup"
				>
					Register Here
				</Link>
			</div>
		</nav>
	);
};

export default Nav;
