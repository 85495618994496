import React, { useState } from "react";
import axios from "axios";
import bgImage from "../assets/chattingbg.jpg";
import Nav from "../Login/Nav";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Button } from "../ui/Button";
import { Loader2 } from "lucide-react";

const RegistrationForm = () => {
	const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const [formData, setFormData] = useState({
		organizationName: "",
		description: "",
		businessEmail: "",
		mobileNumber: "",
	});
	const [submitted, setSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setloading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setloading(true);
		try {
			// Make API call to submit form data
			await axios.post(backendUrl + "/api/v1/auth/register", formData);
			setSubmitted(true);
		} catch (error) {
			setErrorMessage(error.response.data.message);
		}
		setloading(false);
	};

	return (
		<div
			className="page-wrapper"
			id="main-wrapper"
			data-layout="vertical"
			data-navbarbg="skin6"
			data-sidebartype="full"
			data-sidebar-position="fixed"
			data-header-position="fixed"
			style={{ backgroundColor: "#f5f8fb" }}
		>
			<Nav />

			<div className="flex h-screen items-center justify-center">
				<Card className="w-96 bg-blue-100">
					<CardHeader>
						<CardTitle className="flex flex-col items-center justify-center gap-3">
							<img src={bgImage} width="50" alt="" />
							<span className="font-bold text-[#111c2d]">C H A T Y F Y</span>
						</CardTitle>
					</CardHeader>
					<CardContent>
						{errorMessage && (
							<div
								className="alert alert-danger alert-dismissible fade show mt-3 text-center"
								role="alert"
							>
								{errorMessage}
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="alert"
									aria-label="Close"
								></button>
							</div>
						)}

						{!submitted ? (
							<form onSubmit={handleSubmit}>
								<div className="mb-3">
									<label htmlFor="organizationName" className="form-label">
										Organization Name
									</label>
									<input
										type="text"
										className="form-control"
										id="organizationName"
										name="organizationName"
										value={formData.organizationName}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="description" className="form-label">
										Industry
									</label>
									<input
										className="form-control"
										id="description"
										name="description"
										value={formData.description}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="businessEmail" className="form-label">
										Business Email
									</label>
									<input
										type="email"
										className="form-control"
										id="businessEmail"
										name="businessEmail"
										value={formData.businessEmail}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="mobileNumber" className="form-label">
										Mobile Number
									</label>
									<input
										type="tel"
										className="form-control"
										id="mobileNumber"
										name="mobileNumber"
										value={formData.mobileNumber}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="flex justify-center">
									<Button
										className="flex justify-center gap-1"
										disabled={loading}
										type="submit"
									>
										{loading && (
											<Loader2 className="mr-2 h-4 w-4 animate-spin transition-all" />
										)}
										Log In
									</Button>
								</div>
							</form>
						) : (
							<div className="alert alert-success">
								Thanks for registering! We will verify your details and send you
								an onboarding link. Please keep an eye on the registered email{" "}
								<strong>{formData.businessEmail}</strong>. Check out spam folder
								as well. It will take 24 to 48 hours for verification.
							</div>
						)}
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default RegistrationForm;
