import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import navigation from "../_nav";
import logo from "../assets/logo.jpg";
import { AppSidebarNav } from "./AppSidebarNav";

const AppSidebar = () => {
	const dispatch = useDispatch();
	const unfoldable = useSelector((state) => state.sidebarUnfoldable);
	const sidebarShow = useSelector((state) => state.sidebarShow);

	const divStyle = {
		background: "linear-gradient(180deg, #1e1e2f 0%, #2c2c3c 100%)", // Dark gradient background for a sleek look
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Deeper shadow for a more pronounced floating effect
		borderRadius: "12px", // Slightly more rounded corners for a modern touch
		overflow: "hidden", // Ensure clean edges with rounded corners
		color: "#f0f0f0", // Light text color for better contrast
	};

	return (
		<CSidebar
			style={divStyle}
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={(visible) => {
				dispatch({ type: "set", sidebarShow: visible });
			}}
		>
			<CSidebarBrand
				className="d-flex p-2"
				to="/"
				style={{
					borderBottom: "2px solid #ffffff",
				}}
			>
				<CImage
					src={logo}
					className="d-sm-block d-md-none d-lg-block"
					width={100}
					height={20}
					alt="Chatyfy Logo"
				/>
				<div className="ms-2">
					<h4
						className="text-light"
						style={{ color: "#ea3c12", fontWeight: "bold" }}
					>
						CHATYFY
						<span>
							<p
								style={{
									fontSize: "10px",
									color: "white",
									fontWeight: "lighter",
								}}
							>
								Business of Conversations
							</p>
						</span>
					</h4>
				</div>
			</CSidebarBrand>

			<CSidebarNav style={divStyle}>
				<SimpleBar>
					<AppSidebarNav items={navigation} />
				</SimpleBar>
			</CSidebarNav>
		</CSidebar>
	);
};

export default React.memo(AppSidebar);
